<template>
<div>
  <Header/>
  <b-container 
    style="max-width:95%; padding-top:70px;"
  >
    <b-row>
      <b-col>
        <h1>
          施設承認待ちリスト
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col 
        md="1"
      >
        <b-button 
          class="btn btn-success text-nowrap" 
          @click="approvalPutFacility()"
          >
          施設更新
        </b-button>
      </b-col>
      <b-col 
        md="5" 
        class="text-center"
      >
        <b>
          表示件数：{{ facilityDataCount }}件中{{ displayfacility }}件
        </b>
        <paginate
          :page-count="getFacilityPaginateCount"
          :prev-text="'<'"
          :next-text="'>'"
          :click-handler="paginateClickCallbackFacility"
          :container-class="'pagination justify-content-center'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
          :first-last-button="true"
          :first-button-text="'<<'"
          :last-button-text="'>>'"
          :force-page="currentPage"
        >
        </paginate>
      </b-col>
      <b-col 
        md="2"
      >
        <select 
          class="form-select" 
          name="displayLimits" 
          v-model="display"
        >
          <option 
            v-for="displayLimit in displayLimits" 
            :key="'displayLimit-' +displayLimit.id"
            :value="displayLimit.value"
          >
            {{ displayLimit.text }}
          </option>
        </select>
      </b-col>
      <b-col>
        <b-button 
          variant="primary"
          @click="changeLimit"
        >
          表示件数変更
        </b-button>
      </b-col>
    </b-row>

    <span 
      class="text-danger" 
      v-if="message.approvalFacility !== ''"
    >
      {{ message.approvalFacility }}
    </span>

    <b-row>
      <b-col 
        md="8"
      >
        <h5>
          承認待ち施設
        </h5>
        <table 
          class="table table_sticky table-hover table-bordered"
        >
          <thead 
            class="table-secondary"
          >
            <tr>
              <th 
                scope="col" 
                style="width:5%"
              >
                一括チェック<br>
                <input 
                  name="facilityAllApploval"  
                  class="form-check-input" 
                  type="checkbox"
                  @click="selectAllFacility"
                  :checked="isFacilityAllSelected"
                >
              </th>
              <th 
                scope="col" 
                style="width:8%"
              >
                登録日
              </th>
              <th 
                scope="col" 
                style="width:10%"
              >
                施設名<br>詳細名
              </th>
              <th 
                scope="col" 
                style="width:10%"
              >
                施設住所
              </th>
              <th 
                scope="col" 
                style="width:8%"
              >
                施設区分<br>
              </th>
              <th 
                scope="col" 
                style="width:10%"
              >
                施設写真
              </th>
              <th 
                scope="col" 
                style="width:8%"
              >
                登録者<br>
              </th>
              <th 
                scope='col' 
                style="width:15%"
              >
                備考<br>
                管理者コメント
              </th>
              <th 
                scope="col" 
                style="width:5%"
              >
                状態
                <b-button 
                  size="sm"
                  variant="success"
                  @click="changeApploval('facility')"
                  :disabled="facilityCheckApploval === ''"
                >
                  変更
                </b-button><br>
                <b-form-select
                  v-model="facilityCheckApploval"
                  
                >
                  <option 
                    value=""
                  >
                    一括選択
                  </option>
                  <option 
                    class="form-select"
                    v-for="approval in approvals"
                    :value="approval.value"
                    :key="approval.id"
                  >
                  {{ approval.text }}
                  </option>
                </b-form-select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="(facilityData, index) in facilityDatas" 
              :key="index"
              style="cursor:pointer;"
              @click="getApprovalRegistFacility(facilityData)"
              :class="{'table-active': facilityData.active}"
            >
              <th 
                scope="row"
              >
                {{ index + 1 }}<br>
                <input 
                  v-if="role === '0' || userName === facilityData.vcUserLName"
                  name="checkFacility"  
                  class="form-check-input" 
                  type="checkbox"
                  :value="facilityData.inFacilityDetailsID"
                  v-model="checkFacilityApploval"
                /><br>
                <b
                  v-if="facilityData.boExample === 0" 
                >代表</b> 
              </th>
              <td>
                {{ facilityData.dtCreate }}
              </td>
              <td>
                  {{ facilityData.vcFacilityName }}
                <br><br>
                <b-form-input 
                  v-model="facilityData.vcFacilityDetailName">
                  
                </b-form-input>
              </td>
              <td>
                {{ facilityData.vcFacilityAdd }}<br>
                <b-link 
                  :href="googleMapLink + facilityData.doLatitude+',' + facilityData.doLongitude" 
                  target="_blank"
                >
                  GoogleMapへ
                </b-link>
              </td>
              <td>{{ facilityData.vcClassificationName }}</td>
              <td >
                <img 
                  class="img_link" 
                  @click="showImg(facilityData.vcFacilityName,facilityData.vcFacilityDetailName,facilityData.vcStorage)" 
                  style="width:100px;height:100px;" 
                  :src="facilityData.vcStorage"
                >
              </td>
              <td>
                {{ facilityData.vcUserLName }}
              </td>
              <td>
                {{ facilityData.txNote }}
                <br><br>
                <b-form-textarea 
                  v-model="facilityData.txAdComment" 
                  placeholder="差し戻し理由を入力してください"
                >
                </b-form-textarea>
              </td>
              <td>
                <b-form-select 
                  v-model="facilityData.inApproval" 
                >
                  <option
                    class="form-select" 
                    v-for="approval in approvals"
                    :value="approval.value"
                    :key="approval.id"
                  >
                  {{ approval.text }}
                  </option>
                </b-form-select>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>

      <b-col 
        md="4"
      >
        <h5>
          周辺登録済み施設
        </h5>
        <table 
          class="table table_sticky table-hover table-bordered"
        >
          <thead 
            class="table-secondary"
          >
            <tr>
              <th 
                scope="col" 
                style="width:5%"
              >
                施設名<br>詳細名
              </th>
              <th 
                scope="col" 
                style="width:5%"
              >
                施設住所
              </th>
              <th 
                scope="col" 
                style="width:5%"
              >
                施設区分
              </th>
            </tr>
          </thead>
          <tbody 
            v-if="message.approval === ''"
          >
            <tr
              v-for="(approvalRegistList, index) in approvalRegistLists" 
              :key="'registList' + index"
              style="cursor:pointer;"
              @click="getApprovalRegistDetail(approvalRegistList)"
              :class="{'table-active': approvalRegistList.active}"
            >
            
              <td>
                {{ approvalRegistList.vcFacilityName }}<br><br>
                {{ approvalRegistList.vcFacilityDetailName }}
              </td>
              <td>
                {{ approvalRegistList.vcFacilityAdd }}
              </td>
              <td>
                {{ approvalRegistList.vcClassificationName }}
                <br>
                <span 
                  v-if="approvalRegistList.vcMiddleClassName !== ''"
                >
                  {{ approvalRegistList.vcMiddleClassName }}
                </span>
              </td>
            </tr>

          </tbody>
          <tbody v-else>
            <b>
              {{ message.approval }}
            </b>
          </tbody>
        </table>
      </b-col>
    </b-row>

    <facilityDetailModal 
      :facilityDetail="facilityDetail "
      :role="role"
      :selectDetailID="selectDetailID"
    />

  </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import FacilityDetailModal from '../../components/FacilityDetailModal.vue'
import { Apimixin } from '../../mixins/api'


export default {
  title: '施設承認待ちリスト',
  components: {
    Header,
    FacilityDetailModal
  },
  mixins:[Apimixin],
  data() {
    return {
      role:'',
      userName:'',
      checkFacilityApploval:[],
      checkPhotoApploval:[],
      facilityDataCount:[],
      displayfacility:[],
      facilityDatas:[],
      approvalList:[],
      approvalRegistLists:[],
      adminComment:[],
      currentPage : 1, 
      limit: 10,
      isLoading:false,
      upIcon:'chevron-up',
      downIcon:'chevron-down',
      photoDatas:[],
      getPaginateCount:"",
      pageCount:0,
      offsetPhoto:1,
      offsetFacility:1,
      isFacilityAllSelected:false,
      isPhotoAllSelected:false,
      facilityCheckApploval:'',
      photoCheckApploval:'',
      oldFacilityData:[],
      newFacilityName:[],
      approval:[],
      display:'',
      selectDetailID:0,
      approvals:[
        {id:0,text:'未承認',value:0},
        {id:1,text:'承認',value:1},
        {id:2,text:'差し戻し',value:2},
      ],
    }
  },
  created() {
    //申請中施設取得
    if(sessionStorage.getItem('facilityapprovalCurrentPage') !== null
      || sessionStorage.getItem('facilityapprovalLimit') !== null) {
      this.display = this.limit
      this.currentPage = Number(sessionStorage.getItem('facilityapprovalCurrentPage'))
      this.display = Number(sessionStorage.getItem('facilityapprovalLimit'))
      this.limit = Number(sessionStorage.getItem('facilityapprovalLimit'))
      let offsetFacility = Number(sessionStorage.getItem('facilityapprovalOffset'))
      this.getApprovalFacilityList(offsetFacility, this.limit);
    } else {
      this.display = this.limit
      sessionStorage.setItem('facilityapprovalCurrentPage', this.currentPage)
      this.getFacilityLists
    }
    
  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    this.userName = sessionStorage.getItem('userName')
  },
  methods:{
    //ページネーションクリック時現在のページ番号取得
    paginateClickCallbackFacility(pageNum) {
      this.currentPage = Number(pageNum);
      sessionStorage.setItem('facilityapprovalCurrentPage', this.currentPage)
      this.checkFacilityApploval = [];
      this.getFacilityLists;
      //this.$scrollTo('table', 1000, {offset: 60});
    },

    //写真拡大表示
    showImg(vcFacilityName,vcFacilityDetailName,vcStorage){
      if(vcStorage !== '/static/images/noimage.jpg') {
        vcStorage =vcStorage.replace('/resizephoto/','/photo/')
        const h = this.$createElement
        const titleVNode = h('div', { domProps: { innerHTML: vcFacilityName+" "+vcFacilityDetailName } })
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('b-img', {
            props: {
              src: vcStorage,
              thumbnail: true,
              center: true,
              fluid: true,
            }
          })
        ])
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'xl',
          centered: true, size: 'xl'
        })
      }
    },
    //承認、未承認一括変更
    changeApploval(type) {
      if(type === 'facility') {
        for(let i = 0;i < this.facilityDatas.length; i++) {
          this.facilityDatas[i].inApproval = this.facilityCheckApploval
        }
      } 
    },
    //施設チェックボックス一括チェック
    selectAllFacility() {
      if(this.isFacilityAllSelected) {
        this.checkFacilityApploval = []
        this.isFacilityAllSelected = false
      } else {
        this.checkFacilityApploval = []
        for(let key in this.facilityDatas) {
          this.checkFacilityApploval.push(this.facilityDatas[key].inFacilityDetailsID)
        }
        this.isFacilityAllSelected = true
      }
    },
  
    //施設情報更新実行
    approvalPutFacility(){
      if(this.checkFacilityApploval.length !== 0) {
        let self =this
        var approvalList = []
        this.approval = []
        this.newFacilityName = []
        self.checkFacilityApploval.filter(function(value) {
          for(let i = 0;i < self.facilityDatas.length;i++) {
            if(self.facilityDatas[i].inFacilityDetailsID === value) {
              approvalList.push(self.facilityDatas[i])
            }
          }
        })
        
        let error = []
        for(let i = 0;i < approvalList.length;i++) {
          if(approvalList[i].txAdComment === '') {
            approvalList[i].txAdComment = null
          }
          if(approvalList[i].inApproval === 2 &&
            approvalList[i].txAdComment === null ) {
              error.push('「' + approvalList[i].vcFacilityName + '」' + 'の差し戻し理由を入力してください？'+ '<br>')
            } 
          if(approvalList[i].inApproval === 0 &&
            approvalList[i].txAdComment !== null ||
            approvalList[i].inApproval === 1 &&
            approvalList[i].txAdComment !== null) {
              error.push('「'+ approvalList[i].vcFacilityName + '」' + 'の差し戻し理由が入力されています？' + '<br>')
            }
        }
        for(let i = 0;i < approvalList.length;i++) {
          for(let t = 0;t < this.oldFacilityData.length;t++) {
            if(approvalList[i].inFacilityDetailsID === this.oldFacilityData[t].inFacilityDetailsID &&
              approvalList[i].vcFacilityDetailName !== this.oldFacilityData[t].vcFacilityDetailName) {
                this.newFacilityName.push({inFacilityDetailsID:approvalList[i].inFacilityDetailsID,
                vcFacilityDetailName:approvalList[i].vcFacilityDetailName,inFacilityID:approvalList[i].inFacilityID,
                boExample:approvalList[i].boExample,inApproval:approvalList[i].inApproval,txAdComment:approvalList[i].txAdComment,
                oldvcFacilityDetailName:this.oldFacilityData[t].vcFacilityDetailName})
            } else if(approvalList[i].inFacilityDetailsID === this.oldFacilityData[t].inFacilityDetailsID &&
                approvalList[i].vcFacilityDetailName === this.oldFacilityData[t].vcFacilityDetailName) {
              this.approval.push({inFacilityDetailsID:approvalList[i].inFacilityDetailsID,
                vcFacilityDetailName:approvalList[i].vcFacilityDetailName,inFacilityID:approvalList[i].inFacilityID,
                boExample:approvalList[i].boExample,inApproval:approvalList[i].inApproval,txAdComment:approvalList[i].txAdComment})
            }
          }
        }
        let updateFacilityName = []
        if(this.newFacilityName.length !== 0) {
          for(let i = 0; i < this.newFacilityName.length; i++) {
            updateFacilityName.push('「 ' + this.newFacilityName[i].oldvcFacilityDetailName + '」' + 
            'の施設名を「' + this.newFacilityName[i].vcFacilityDetailName +'」に変更しますか？' + '<br>')
          }
        }
        let approvalFacility = []
        if(this.approval.length !== 0) {
          for(let i = 0; i < this.approval.length;i++) {
            approvalFacility.push('「'+ approvalList[i].vcFacilityName + '」' + 'の承認情報を更新しますか？' + '<br>')
          }
        }
          if(error.length !== 0) {
            this.$swal({
              icon: 'error',
              html:`${error}`,
              confirmButtonText:"閉じる"
            })
          }else {
            this.$swal({
              title: '施設承認状況の更新',
              html: `${approvalFacility}${updateFacilityName}`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: '確定',
              cancelButtonText:'キャンセル'
            }).then((result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: '処理中',
                html: '処理終了まで画面はそのままにしてください。', 
                allowOutsideClick : false , 
                showConfirmButton: false, 
                onBeforeOpen: () => {
                  this.$swal.showLoading()
                }
              }); 
              this.putFacilityData(this.approval,this.newFacilityName)
            }
          })
        }
      } else {
        this.$swal({
          icon: 'error',
          title:'施設をチェックボックスで選択してください。',
          confirmButtonText:"閉じる"
        })
      }
    },

    //登録済みの施設か検索
    getApprovalRegistFacility(facilityData) {
      for(let i = 0;i < this.facilityDatas.length;i++) {
        if(this.facilityDatas[i].inFacilityDetailsID === facilityData.inFacilityDetailsID) {
          this.facilityDatas[i].active = true
        } else {
          this.facilityDatas[i].active = false
        }
      } 
      this.getApprovalRegisteredList(facilityData.doLatitude,facilityData.doLongitude)
    },
    //既存施設の詳細情報取得
    getApprovalRegistDetail(approvalRegistList) {
      this.selectDetailID = 0
      console.log(approvalRegistList.inFacilityDetailsID)
      this.selectDetailID = approvalRegistList.inFacilityDetailsID
      this.getFacilityDetail(approvalRegistList.inFacilityID,'detail')
      for(let i = 0;i < this.approvalRegistLists.length;i++) {
        if(this.approvalRegistLists[i].inFacilityDetailsID === approvalRegistList.inFacilityDetailsID) {
          this.approvalRegistLists[i].active = true
        } else {
          this.approvalRegistLists[i].active = false
        }
      }
      this.$bvModal.show('FacilityDetailModal')
    },

    //表示件数変更
    changeLimit() {
      this.limit = this.display
      sessionStorage.setItem('facilityapprovalLimit', JSON.stringify(this.limit))
      this.currentPage = 1
      this.getFacilityLists
    }
  },
  
  computed: {
    //申請中施設一覧取得
    getFacilityLists() {
      let offsetFacility = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('facilityapprovalOffset', offsetFacility)
      sessionStorage.setItem('facilityapprovalLimit', this.limit)
      return this.getApprovalFacilityList(offsetFacility, this.limit);
    },

    //施設総ページ数の計算
    getFacilityPaginateCount() {
      if (this.facilityDataCount >=1){
        return Math.ceil(this.facilityDataCount / this.limit);
      }else{
        return 1
      }
    }
  },
  watch:{
    checkFacilityApploval:function() {
      if (this.checkFacilityApploval.length !== this.facilityDatas.length) {
        this.isFacilityAllSelected = false
      } else {
        this.isFacilityAllSelected = true
      }
    },
    'currentPage':function() {
      sessionStorage.setItem('facilityapprovalCurrentPage', this.currentPage)
    }
  }
}
</script>
<style scoped>
.table_sticky {
    display: block;
    overflow-y: scroll;
    height: calc(72vh);
}
.table_sticky thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

</style>