<template>
<b-modal
  id="FacilityDetailModal"
  title="施設詳細情報"
  size="lg"
  hide-header-close
  hide-footer
  >
<b-row>
  <b-row class="mt-2">
    <b-col >
      施設名：{{ facilityName }}
    </b-col>
  </b-row>

    <b-row class="mt-2">
      <b-col >
        住所：{{ address }}
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col >
        大区分：{{ facilityType }}
      </b-col >
    </b-row>

    <b-row v-if="middleFacilityType !== ''" class="mt-2">
      <b-col >
        中区分：{{ middleFacilityType }}
      </b-col >
    </b-row>
    
    <b-row class="mt-2" v-if="tags.length !== 0">
      <b-col>
        <div 
          v-for="facilityTag in tags"
          :key="facilityTag.vcTagName + facilityTag.insort"
          class="text-nowrap"
        >
          タグ：{{ facilityTag.vcTagName }}
        </div>
      </b-col>
    </b-row>

    <dl class="row mt-2" v-if="train.length !== 0">
      <b-col>
        <div 
          v-for="trainData in train" 
          :key="trainData.vcKindaikaRouteName + trainData.iRouteNo" 
          class="text-nowrap "
        >
          路線：{{ trainData.vcKindaikaRouteName }}
        </div>
      </b-col>
    </dl> 

    <b-tabs 
      v-model="tabIndex"
      class="mt-2"
    >
      <b-tab title="代表情報">
        <b-row class="mt-2">
          <b-col >
              緯度：{{ lat }}
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            経度：{{ lng }}
          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="role == 0">
          <b-col>
            補正値：{{ correctionVal }}
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            代表施設備考：{{ facilityNote }}
          </b-col>
        </b-row>

        <b-row class="flex-row">
          <b-col >
            <carousel 
              :per-page="3" 
              :navigationEnabled="true" 
              navigationPrevLabel="〈 戻る" 
              navigationNextLabel="次へ 〉"
            >
              <slide 
                class="p-2 " 
                v-for="(facilityData, index) in facility " :key="'facility-' + index"
              >
              <b-card-group deck class="mx-auto" style="max-width: 300px; " >
                <b-card 
                  :class="{'img_link': facilityData.vcStorage !== noImage}"
                  @click="showImg(facilityName,facilityData.vcFacilityDetailName,facilityData.vcStorage)"
                  img-height="230px"  
                  :img-src="facilityData.vcStorage"
                  img-top
                >
                  <b-card-text>
                    ・撮影日：{{ facilityData.dtPhotoDate }}<br>
                    ・次回撮影日：{{ facilityData.dtNextShootingDate }}<br>
                    ・公開日：{{ facilityData.dtReleaseTo }}<br>
                    ・公開終了日：{{ facilityData.dtReleaseFrom }}<br>
                    ・撮影者：{{ facilityData.vcUserLName }}
                  </b-card-text>
                </b-card>
              </b-card-group>
              </slide>
            </carousel>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab 
        v-for="(detail, index) in details" 
        :key="'detail' + index"
        :title="detail[0].vcFacilityDetailName"
        lazy
      >
        <b-row >
          <b-row class="mt-2">
            <b-col >
              名称：{{ detail[0].vcFacilityDetailName }}
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
                緯度：{{ detail[0].doLatitude }}
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              経度：{{ detail[0].doLongitude }}
            </b-col>
          </b-row>

          <b-row class="mt-2" v-if="role == 0">
            <b-col>
              補正値：{{ detail[0].vcCorrectionVal }}
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              備考：{{ detail[0].txNote }}
            </b-col>
          </b-row>
        </b-row>

        <b-row class="flex-row">
          <b-col>
            <carousel
              :per-page="3" 
              :navigationEnabled="true" 
              navigationPrevLabel="〈 戻る" 
              navigationNextLabel="次へ 〉"
            >
              <slide 
                class="p-2 " 
                v-for="(detailsImage, key) in details[index] " 
                :key="'detailsImage-' + key"
              >
                <b-card-group deck 
                  class="mx-auto" 
                  style="max-width: 300px; " 
                >     
                  <b-card 
                    :class="{'img_link': detailsImage.vcStorage !== noImage}" 
                    @click="showImg(facilityName, detailsImage.vcFacilityDetailName, detailsImage.vcStorage)"
                    img-height="230px"  
                    :img-src="detailsImage.vcStorage"  
                    img-top
                  >
                    <b-card-text>
                      ・撮影日：{{ detailsImage.dtPhotoDate  }}<br>
                      ・次回撮影日：{{ detailsImage.dtNextShootingDate }}<br>
                      ・公開日：{{ detailsImage.dtReleaseTo }}<br>
                      ・公開終了日：{{ detailsImage.dtReleaseFrom }}<br>
                      ・撮影者：{{ detailsImage.vcUserLName }}
                    </b-card-text>
                  </b-card>
                </b-card-group>
              </slide>
            </carousel>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs> 
</b-row>
</b-modal>  
</template>

<script>


export default{
  props:{
    facilityDetail:[],
    role:String,
    selectDetailID:Number
  },
  data() {
    return {
      facilityType:'',
      middleFacilityType:'',
      facilityName:'',
      mainDetailsID:'',
      createDate:'',
      address:'',
      facilityNote:'',
      correctionVal:'',
      lat:'',
      lng:'',
      tags:[],
      train:[],
      facility:[],
      details:[],
      facilityDetailID:[],
      tabIndex:0,
    }
  },

  methods:{
    //写真拡大表示
    showImg(vcFacilityName,vcFacilityDetailName,vcStorage){
      if(vcStorage !== '/static/images/noimage.jpg') {
        vcStorage =vcStorage.replace('/resizephoto/','/photo/')
        const h = this.$createElement
        const titleVNode = h('div', { domProps: { innerHTML: vcFacilityName+" "+vcFacilityDetailName } })
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('b-img', {
            props: {
              src: vcStorage,
              thumbnail: true,
              center: true,
              fluid: true,
            }
          })
        ])
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'xl',
          centered: true, size: 'xl'
        })
      }
    },
    //選択された施設の詳細情報取得
    getSelectFacilityData() {
      this.tags=[],
      this.train=[],
      this.facility=[],
      this.details=[],
      this.facilityDetailID=[]
      if(this.facilityDetail.tags[0].inTagID !== null){
        for(let i = 0;i < this.facilityDetail.tags.length; i++) {
          this.tags.push(this.facilityDetail.tags[i])
        }
      }
      if(this.facilityDetail.train[0].iRouteNo !== null) {
        for(let i = 0;i < this.facilityDetail.train.length; i++) {
          this.train.push(this.facilityDetail.train[i])
        }
      }

      for(let i = 0; i < this.facilityDetail.master.length; i++) {
        this.facilityDetailID.push(this.facilityDetail.master[i].inFacilityDetailsID)
        this.facilityDetail.details[this.facilityDetail.master[i].inFacilityDetailsID]
          = JSON.parse(this.facilityDetail.details[this.facilityDetail.master[i].inFacilityDetailsID])
        
        if(this.facilityDetail.master[i].inFacilityDetailsID === this.facilityDetail.details[this.facilityDetailID[i]][0].inFacilityDetailsID) {
          this.facilityDetail.details[this.facilityDetailID[i]][0]['doLatitude'] = this.facilityDetail.master[i].doLatitude
          this.facilityDetail.details[this.facilityDetailID[i]][0]['doLongitude'] = this.facilityDetail.master[i].doLongitude
          this.facilityDetail.details[this.facilityDetailID[i]][0]['vcCorrectionVal'] = this.facilityDetail.master[i].vcCorrectionVal
        }
        this.address = this.facilityDetail.master[0].vcFacilityAdd
        
        if(this.facilityDetail.master[0].vcMiddleClassName === null || this.facilityDetail.master[0].vcMiddleClassName === '') {
          this.facilityType = this.facilityDetail.master[0].vcClassificationName
        } else {
          this.middleFacilityType = this.facilityDetail.master[0].vcMiddleClassName
        }

        if(this.facilityDetail.details[this.facilityDetailID[i]][0].boExample == 0 ) {
          this.facilityName = this.facilityDetail.details[this.facilityDetailID[i]][0].vcFacilityDetailName
          this.facilityNote = this.facilityDetail.details[this.facilityDetailID[i]][0].txNote

          this.mainDetailsID = this.facilityDetail.details[this.facilityDetailID[i]][0].inFacilityDetailsID
          this.lat = this.facilityDetail.details[this.facilityDetailID[i]][0].doLatitude
          this.lng = this.facilityDetail.details[this.facilityDetailID[i]][0].doLongitude
          this.correctionVal = this.facilityDetail.details[this.facilityDetailID[i]][0].vcCorrectionVal
          this.facility = (this.facilityDetail.details[this.facilityDetailID[i]])
          this.facility[0]['tabIndex'] = 0
        } else {
          this.details.push(this.facilityDetail.details[this.facilityDetailID[i]])
        }
      }
      let tabIndex = 1
      for(let i = 0;i < this.details.length;i++) {
        this.details[i][0]['tabIndex'] = tabIndex
        tabIndex++
      }
      for(let t = 0;t < this.facility.length; t++) {
        if(this.facility[t].vcStorage === null) {
          this.facility[t].vcStorage = this.noImage
          this.facility[t].dtNextShootingDate = '未撮影'
          this.facility[t].dtPhotoDate = '未撮影'
          this.facility[t].dtReleaseFrom = '未撮影'
          this.facility[t].dtReleaseTo = '未撮影'
          this.facility[t].photoCreate = '未撮影'
          this.facility[t].vcUserLName = '未撮影'
        } else {
          this.facility[t].vcStorage = this.resizeImgURL + this.facility[t].vcStorage
          this.facility[t].dtNextShootingDate = this.facility[t].dtNextShootingDate.split('T')[0]
          this.facility[t].dtPhotoDate = this.facility[t].dtPhotoDate.split('T')[0]
          this.facility[t].dtReleaseFrom = this.facility[t].dtReleaseFrom.split('T')[0]
          this.facility[t].dtReleaseTo = this.facility[t].dtReleaseTo.split('T')[0]
          this.facility[t].photoCreate = this.facility[t].photoCreate.split('T')[0]
        }
      }
      // this.createDate = this.facility[0].photoCreate.split('T')[0]
      for(let i = 0;i < this.details.length; i++) {
        for(let t = 0;t < this.details[i].length;t++) {
          if(this.details[i][t].vcStorage === null) {
            this.details[i][t].vcStorage = this.noImage
            this.details[i][t].dtNextShootingDate = '未撮影'
            this.details[i][t].dtPhotoDate = '未撮影'
            this.details[i][t].dtReleaseFrom = '未撮影'
            this.details[i][t].dtReleaseTo = '未撮影'
            this.details[i][t].photoCreate = '未撮影'
            this.details[i][t].vcUserLName = '未撮影'
          } 
          else {
            this.details[i][t].vcStorage = this.resizeImgURL + this.details[i][t].vcStorage
            this.details[i][t].dtNextShootingDate = this.details[i][t].dtNextShootingDate.split('T')[0]
            this.details[i][t].dtPhotoDate = this.details[i][t].dtPhotoDate.split('T')[0]
            this.details[i][t].dtReleaseFrom = this.details[i][t].dtReleaseFrom.split('T')[0]
            this.details[i][t].dtReleaseTo = this.details[i][t].dtReleaseTo.split('T')[0]
            this.details[i][t].photoCreate = this.details[i][t].photoCreate.split('T')[0]
          }
        }
        this.details[i][0].detailCreate = this.details[i][0].detailCreate.split('T')[0]
      }
    },

    //該当のタブへ遷移
    getTabIndex() {
      let timer = setInterval(() => {
          clearInterval(timer);
          if(this.facility[0].inFacilityDetailsID == this.selectDetailID) {
            this.tabIndex = Number(this.facility[0].tabIndex)
          } else {
            for(let i = 0;i < this.details.length;i++) {
              if(this.details[i][0].inFacilityDetailsID == this.selectDetailID) {
                this.tabIndex = Number(this.details[i][0]['tabIndex'])
              }
            }
          }
      },500)
    },
  },
  watch:{
    'facilityDetail':function() {
      this.getSelectFacilityData()
      this.getTabIndex()
    },
  }
}


</script>